// i18next-extract-mark-ns-start shopify-payment-gateway
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';

import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Partners} from 'components/Partners';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import shopify_monei from 'images/shopify_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';
import {AnchorLink} from 'components/AnchorLink';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {InternalPageLink} from 'components/links/Pages';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const Disclaimer = styled.p`
  font-size: 0.9rem;
  line-height: 1.476rem;
  font-weight: 500;
  strong {
    font-weight: 500;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const ShopifyPaymentGateway: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        path="shopify-payment-gateway"
        title={t('Shopify Payment Gateway')}
        description={t(
          'Grow your Shopify store with MONEI’s payment gateway: Accept all major payment methods. Quick and easy integration. Dynamic pricing. Sign up now »'
        )}
        bodyAttributes={{class: 'selling-page'}}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>
                  Grow your e-commerce business faster with the advanced Shopify payment gateway
                </Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                You chose Shopify as your e-commerce platform because it’s easy to use, affordable,
                and scales with your business. Why not use a payment gateway that offers the same
                benefits to help you grow your Shopify store?
              </Trans>
              <Trans parent="p">
                MONEI fully integrates with Shopify so you can accept all major and alternative
                payment methods in your store.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={shopify_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Increase sales by accepting more payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> helps
                you reach more people, increase customer satisfaction, and boost sales. That’s why
                we aggregate all payment methods into a single platform to help you offer your
                customers their preferred payment options.
              </Trans>
              <List>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/credit-cards">
                    Credit cards
                  </InternalPageLink>{' '}
                  - <InternalPageLink slug="visa-mastercard-for-shopify">Visa</InternalPageLink>,
                  Mastercard, and more
                </Trans>
                <Trans parent="li">
                  Digital wallets -{' '}
                  <InternalPageLink slug="paypal-for-shopify">PayPal</InternalPageLink>,{' '}
                  <InternalPageLink slug="apple-pay-for-shopify">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="google-pay-for-shopify">Google Pay</InternalPageLink>, and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Local payment methods -{' '}
                  <InternalPageLink slug="bizum-for-shopify">Bizum</InternalPageLink>
                  (in Spain)
                </Trans>
                <Trans parent="li">
                  Direct Debit -{' '}
                  <InternalPageLink slug="payment-methods/sepa-direct-debit">SEPA</InternalPageLink>
                  ,{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <InternalPageLink slug="features/recurring-payments">
                    Subscription payments
                  </InternalPageLink>
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Build trust by customizing your Shopify checkout experience</Trans>
              </SectionHeader>
              <Trans parent="p">
                Use the{' '}
                <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page">
                  Hosted Payment Page
                </AnchorLink>{' '}
                to build an outstanding checkout experience. Improve conversions with responsive
                designs, and{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/categories/360003383258-Customization">
                  customize the appearance
                </AnchorLink>{' '}
                of your mobile-ready checkout page with your logo and brand colors.
              </Trans>
              <Trans parent="p">
                Build trust and brand awareness by adding your custom URL so shoppers stay on your
                website during the entire checkout process.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy lower fees and faster payment settlements</Trans>
              </SectionHeader>
              <Trans parent="p">
                Benefit from competitive rates with our dynamic billing model — as you sell more
                your transaction fees decrease. Quickly reinvest your profits into your business
                thanks to 1-day payment settlements.
              </Trans>
              <Trans parent="p">
                Choose a flexible payment gateway that grows with your business —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <Disclaimer>
                <Trans parent="strong">
                  Note: 24-hour payment settlements are available once you've become a verified
                  MONEI merchant.
                </Trans>
              </Disclaimer>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content fullWidth style={{marginTop: '60px'}}>
        <Section textAlign="center">
          <div>
            <Content>
              <SectionHeader underline tagName="h3">
                <Trans>Quickly and easily integrate with your Shopify store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Integrate with your online store using our{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify">
                  Shopify plugin
                </AnchorLink>{' '}
                to start accepting more payment methods today.
              </Trans>
              <Trans parent="p">
                Once you’ve created your{' '}
                <AnchorLink href="https://dashboard.monei.com/">MONEI account</AnchorLink>, use our{' '}
                <AnchorLink href="https://docs.monei.com/api/#tag/Payments">
                  Payments API
                </AnchorLink>{' '}
                to install and configure MONEI in your Shopify payment settings. In a few short
                steps, you’ll be ready to accept and manage payments from your Shopify dashboard.
              </Trans>
            </Content>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default ShopifyPaymentGateway;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "shopify-payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
